import InstoreHelper from '@/store/modules/instoreHelper'

const state = {
  scanAppToken: undefined,
  scannedCode: undefined,
  connectedVisit: undefined,
  scanLogs: []
}

const getters = {
  scanLogs: state => state.scanLogs,
  // scanAppToken is the token part of the QR encoded URL shown to the student user
  // They can scan the QR code with the mobile app to connect their mobile and then start scanning meds or patients
  scanAppToken: state => state.scanAppToken,
  connectedVisit: state => state.connectedVisit
}

const debug = true

const actions = {
  appendLog (context, event) {
    context.commit('_appendLog', event)
  },

  setConnectedVisit (context, visit) {
    context.commit('setConnectedVisit', visit)
  }
}

const mutations = {
  _appendLog: (state, event) => {
    const log = {
      timestamp: new Date().toLocaleTimeString(),
      message: event
    }
    state.scanLogs.push(log)
    state.scanLogs = [...state.scanLogs]
  },
  setConnectedVisit: (state, visit) => {
    state.connectedVisit = visit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
