<template lang='pug'>
  div
    div(class='multi-patient-bar')
      ui-button(value="empb-create",
        v-if='showCreatePatient',
        :disabled='disableNavAction',
        v-on:buttonClicked='openPatientCreateDialog'
      )
        fas-icon(class="fa", :icon="appIcons.new")
        span &nbsp; {{ ehrText["epcd.title"] }}
      ui-button(value="empb-search",
        v-if='showSearchPatient',
        :disabled='disableNavAction',
        v-on:buttonClicked='searchPatient'
        )
        fas-icon(class="fa", icon="search")
        span &nbsp; {{ ehrText.searchButtonLabel }}
      ehr-patient-tab(v-for='dbObject in activePatientList',
        :key='dbObject._id',
        :dbObject='dbObject',
        :selected='isTabActive(dbObject)',
        @remove='removePatient',
        @select='selectPatient'
      )
    ehr-patient-search-dialog(
      ref='theDialog',
      @openPatient='openPatient')
    ehr-patient-create-dialog(
      ref='createDialog',
      @createPatient='createPatient'
    )
</template>

<script>
import EhrPatientTab from '@/inside/components/EhrPatientTab.vue'
import EhrPatientSearchDialog from '@/inside/components/EhrPatientSearchDialog.vue'
import EhrPatientCreateDialog from '@/inside/components/EhrPatientCreateDialog.vue'
import UiButton from '@/app/ui/UiButton.vue'
import StoreHelper from '@/helpers/store-helper'
import MPatientHelper from '@/helpers/mPatientHelper'
import { t18EhrText } from '@/helpers/ehr-t18'
import { APP_ICONS } from '@/helpers/app-icons'

export default {
  components: { UiButton, EhrPatientSearchDialog, EhrPatientCreateDialog, EhrPatientTab },
  data () {
    return {
      appIcons: APP_ICONS
    }
  },
  computed: {
    ehrText () { return t18EhrText() },
    activePatientList () {
      // array of db objects (seed or patient) where each has an _id property
      return MPatientHelper.getCurrentPatientList()
    },
    currentPatientObjectId () {
      return MPatientHelper.getCurrentPatientObjectId()
    },
    disableNavAction () { return this.$store.state.system.isEditing },
    showSearchPatient () {
      // seed editors open existing case studies.
      // students add patients (case studies) to their patient list
      return StoreHelper.isStudent() || StoreHelper.isSeedEditing()
    },
    showCreatePatient () {
      // seed editors must create a new case study
      // student users can work with many case studies and/or create a new patient
      return StoreHelper.isStudent()
    }
  },
  methods: {
    async createPatient (pData) {
      await MPatientHelper.createStudentPatient(pData)
      // After creation activate the tab via going to the home ehr page
      await this.$router.push(this.routerRoute(false, pData.patientId))
    },
    isTabActive (dbObject) {
      return MPatientHelper.testMatchCurrentPatient(dbObject)
    },
    async openPatient (dbObject) {
      if (dbObject._id !== this.currentPatientObjectId) {
        await this.$router.push(this.routerRoute(false, dbObject._id))
      }
    },
    async openPatientCreateDialog () {
      let mrn = await MPatientHelper.generateNewEhrPatientId()
      this.$refs.createDialog.showPatientCreateDialog(mrn)
    },
    removePatient (id) {
      this.$store.dispatch('mPatientStore/removePatient', id)
    },
    routerRoute (select, id) {
      let route = {}
      // TODO In an ideal world we'd check to see if the appType for the next patient is the same as the current patient
      route.name = select ? this.$route.name : 'ehr'
      if (StoreHelper.isSeedEditing()) {
        route.query = { seedEditId: id, r: (new Date()).getTime() }
      } else {
        route.query = { patientId: id, r: (new Date()).getTime() }
      }
      return route
    },
    searchPatient () {
      this.$refs.theDialog.showPatientDialog()
    },
    selectPatient (id) {
      this.$router.push(this.routerRoute(true, id))
    }
  }
}
</script>

