<template lang="pug">
  div
    app-dialog(
      :isModal="false",
      small=false,
      ref="theDialog",
      :useSave="false",
      @cancel="cancelDialog",
      :cancelButtonLabel='ehrText.closeButtonLabel',
      :zIndexBase=10000
    )
      h2(slot="header") {{ title  }}
      div(slot="body")
        div(class="qr-area")
          qrcode-vue(:value="linkValue", size="300", level="H", margin="5")

        p.
          With your smartphone, get the EdEHR app from the iStore or Google Play.
        p.
          Next you must connect your phone to EdEHR. Open the mobile app and press Get Started.
        p.
          Press the 'Connect' button on this screen.  Use your phone and scan the QR code.
        p.
          We respect your privacy and made sure this
          QR code does not have any information that can identify you outside of the EdEHR.  Once you've scanned this QR code EdEHR will be ready for you to scan any simulated patient wrist bands, medications, accessioning labels, or more.
        .linkValue(v-if="isDev")
          a(:href='linkValue') DevTest

</template>

<script>
import AppDialog from '@/app/components/AppDialogShell'
import { t18EhrText } from '@/helpers/ehr-t18'
import StoreHelper from '@/helpers/store-helper'
import QrcodeVue from 'qrcode.vue'
const LOCALHOST = window.location.origin.includes('localhost')
export default {
  components: {
    AppDialog,
    QrcodeVue
  },
  data: function () {
    return {
    }
  },
  computed: {
    isDev () { return LOCALHOST },
    ehrText () {
      return t18EhrText()
    },
    linkValue () {
      let url = StoreHelper.apiUrlGet()
      const userId = this.$store.getters['userStore/userId']
      return url + '/scan-app/scan/' + userId
    },
    title () {
      return 'Scan and connect'
    }
  },
  methods: {
    showDialog: function () {
      this.$refs.theDialog.onOpen()
    },
    cancelDialog: function () {
      this.$refs.theDialog.onClose()
    },
  }
}
</script>

<style lang='scss' scoped>
.linkValue {
  overflow-x: scroll;
  width: 100%;
}
.qr-area {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
}
</style>
