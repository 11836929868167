<template lang="pug">
  footer
    .container
      .left
        div(class="footer-section", v-if="showEdEhrOrg")
          ed-ehr-org-footer
        div(class="footer-section")
          div {{ apiData.appTitle }}
          div {{ authData.timeRemaining }}
          div Application version: {{ appVersion }}.
      .right
        span &nbsp;
    div(v-if="isAdminZone", class="error-testing")
      button(@click="throwError")  error testing
</template>

<script>
import StoreHelper from '../../helpers/store-helper'
import EdEhrOrgFooter from '@/app/edehr-org/EdEhrOrgFooter'
import edherorg from '@/app/edehr-org/ed-ehr-org'
import UiLink from '@/app/ui/UiLink'
import UiButton from '@/app/ui/UiButton.vue'

export default {
  components: {
    UiButton,
    UiLink,
    EdEhrOrgFooter
  },
  computed: {
    appLmsEnabled () { return this.$store.getters['appLmsStore/appLmsEnabled']},
    visitId () { return this.$store.getters['authStore/visitId']},
    isStudent () { return StoreHelper.isStudent()  },
    isInstructorAsStudent () { return StoreHelper.isInstructorAsStudent() },
    apiData () { return StoreHelper.getApiData()   },
    authData () { return StoreHelper.getAuthData() },
    appVersion () {
      return StoreHelper.getAppVersion()
    },
    classMain () {
      return this.showEdEhrOrg ? 'is-6' : 'is-12'
    },
    isAdminZone () { return StoreHelper.inZoneAdmin() },
    showEdEhrOrg () {
      return edherorg.isEdEhrOrg()
    },
  },
  methods: {
    throwError () {
      throw new Error('Sentry Error')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/definitions';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  color: white;
}
.footer-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.error-testing button {
  background-color: transparent;
  color: black
}
.qr-area {
  background-color: white;
  padding: 5px;
}
</style>
