<template lang="pug">
  div
    app-dialog(
      :isModal="false",
      small=false,
      ref="theDialog",
      :useSave="false",
      @cancel="cancelDialog",
      :cancelButtonLabel='ehrText.closeButtonLabel',
      :zIndexBase=10000
    )
      h2(slot="header") EdEHR Mobile App
      div(slot="body")
        div.panel
          div.upper-panel
            div(v-if='isConnectForCurrentVisit')
              p.
                Use your smartphone as a scanner with the EdEHR Mobile App.
              ul
                li Try scanning any patient or medication barcode/QR code.
                li Verify you see the code in the panel below
                li Open the BarCode med admin dialog and use your mobile as a scanner
                li Scan your patient's MRN or scan medications.
            div(v-else)
              p.
                Use your smartphone as a scanner with the EdEHR Mobile App.
              ul
                li Get the app from the iStore or Google Play.
                li.
                  Next connect your phone with this application.
                  Click 'Connect' button and scan the QR code with your phone to
                  establish a connection between your phone and your work here.
                li Check the console below for a message ...
                li Once the connection is made, close the connection dialog, and then try scanning any other QR or barcode and verify you see the code in the console below.
                li You are now ready to use your phone to scan any EdEHR barcode or QR code.
            ui-button(@buttonClicked="openConnectQR")
              span Connect
          div.lower-panel
            h5 Event console
            .logContainer(ref="logContainer")
              div.log-entry(v-for="(log, index) in scanLogs" :key="index")
                span.logTimestamp {{ log.timestamp }}
                span.logMessage {{ log.message }}

    scan-q-r-dialog(ref='QRDialog')

</template>

<script>
import AppDialog from '@/app/components/AppDialogShell'
import { t18EhrText } from '@/helpers/ehr-t18'
import ScanQRDialog from '@/inside/components/scan-app/ScanQRDialog.vue'
import UiButton from '@/app/ui/UiButton.vue'
export default {
  components: {
    UiButton,
    ScanQRDialog,
    AppDialog
  },
  data: function () {
    return {
    }
  },
  computed: {
    ehrText () { return t18EhrText() },
    connectedVisitId () { return this.$store.getters['scanAppStore/connectedVisit'] },
    currentVisitId () { return this.$store.getters['visit/visitId']},
    isConnectForCurrentVisit () { return false  },
    scanLogs () { return this.$store.getters['scanAppStore/scanLogs']}
  },
  methods: {
    openConnectQR () {
      this.$refs.QRDialog.showDialog()
    },
    showDialog: function () {
      this.$refs.theDialog.onOpen()
      this.scrollLogs()
    },
    scrollLogs () {
      this.$nextTick(() => {
        const container = this.$refs.logContainer
        if (container) {
          container.scrollTop = container.scrollHeight
        }
      })
    },
    cancelDialog: function () {
      this.$store.dispatch('system/setDialogScanConnectVisible', false)
      this.$refs.theDialog.onClose()
    },
  },
  watch: {
    scanLogs () {
      this.scrollLogs()
    }
  }

}
</script>

<style lang='scss' scoped>
.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upper-panel {
  flex: 0 0 auto;
  padding: 8px;
  //background: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lower-panel {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 8px;
  background: #ffffff;
  border-top: 1px solid #ccc;
}

.logContainer {
  height: 10rem;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 8px;
  background: #1e1e1e; /* Dark background like dev tools */
  color: #d4d4d4; /* Default text color */
  font-family: Consolas, 'Courier New', monospace; /* Monospace font */
  line-height: 1.4;
}
.log-entry {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid #3c3c3c;
  font-family: Consolas, 'Courier New', monospace;
  color: #d4d4d4;
}

.log-entry:last-child {
  border-bottom: none;
}

.logTimestamp {
  width: 10rem; /* Fixed width for alignment */
  text-align: right;
  margin-right: 2rem;
}

.logMessage {
  flex: 1; /* Allow the message to take up remaining space */
  font-weight: bold;
  white-space: pre-wrap; /* Preserve whitespace for multiline messages */
}

.log-entry.error .message {
  color: #f44747;
}
</style>
