import store from '@/store'
import Vue from 'vue'
import EventBus, {  CODE_SCAN_EVENT } from '@/helpers/event-bus'

class ScanAppHelperInner {

  /**
   * Handle the push from server notifications.
   * If the connected user is not the current user then stop.
   * Else
   *    1. Set the visit into the scan store
   *    2. Force open the scan dialog.
   * @param visitId
   * @param userId
   * @returns {Promise<void>}
   */
  async scanConnect (visitId, userId) {
    if (!this.validateUser(userId)) {
      // silently do nothing if the message is for any user other than the authenticated user.
      return
    }
    const currentVisitId = store.getters['visit/visitId']
    let event = ''
    if (currentVisitId === visitId) {
      event = 'Your mobile is connected and ready to scan.'
    } else {
      event = 'Connection attempt for different activity.'
    }
    console.log('scanConnect. User has connected with their mobile.', visitId)
    await store.dispatch('scanAppStore/setConnectedVisit', visitId)
    await this.appendLog(event)
    await this.openScanDialogForUser()
  }

  async scanCode (visitId, userId, code) {
    if (!this.validateUser(userId)) {
      return
    }
    const currentVisitId = store.getters['visit/visitId']
    let event = ''
    if (currentVisitId === visitId) {
      event = 'Scan code: "' + code + '"'
      updateFocusedTextInput(code)
    } else {
      event = 'Code scanned for different activity'
    }
    await this.appendLog(event)
    // await store.dispatch('scanAppStore/setConnectedVisit', visitId)
  }

  async appendLog (event) {
    await store.dispatch('scanAppStore/appendLog', event)
  }

  validateUser (userId ) {
    const thisClientUser = store.getters['userStore/userId']
    if (thisClientUser !== userId) {
      console.log('Scan message is for user:', userId , 'not this user:', thisClientUser)
    }
    return thisClientUser === userId
  }
  async openScanDialogForUser () {
    await store.dispatch('system/setDialogScanConnectVisible', false)
    await store.dispatch('system/setDialogScanConnectVisible', true)
  }

}
function updateFocusedTextInput (newText) {
  Vue.nextTick(() => {
    const focusedElement = document.activeElement
    if (focusedElement &&
      focusedElement.tagName === 'INPUT' &&
      focusedElement.type === 'text') {
      // Set the value and simulate a "carriage return"
      focusedElement.value = newText // + "\n";
      // Find all focusable elements and advance focus
      const focusableElements = Array.from(
        document.querySelectorAll('input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])')
      )
      const currentIndex = focusableElements.indexOf(focusedElement)
      const nextElement = focusableElements[currentIndex + 1]
      if (nextElement) {
        nextElement.focus()
      }
      // The event fires and is handled async.
      // in the payload provide the current focused element
      // also provide the new text so the handler can use this if needed.
      // See MarBarCodeDialog for example usage.
      EventBus.$emit(CODE_SCAN_EVENT, {newText, focusedElement})
    }
  })
}


const ScanAppHelper = new ScanAppHelperInner()
export default ScanAppHelper
