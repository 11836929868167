import store from '@/store'

class EhrConstant {
  /**
   * @param {string} key - Unique key for the constant.
   * @param {string|number|boolean} defaultValue - Default value of the constant.
   * @param {RegExp} regex - Regular expression for search and replace.
   * @param {string} description - Description of the constant.
   */
  constructor (key, defaultValue, regex, description) {
    if (!key || typeof key !== 'string') {
      throw new Error('Key must be a non-empty string.')
    }
    if (!regex || !(typeof regex === 'string' || regex instanceof RegExp)) {
      throw new Error('RegExp must be a non-empty string or a RegExp.')
    }
    this.key = key
    this.defaultValue = defaultValue
    this.regex = regex instanceof RegExp ? regex : new RegExp(regex)
    this.description = description || ''
  }
}

class EhrConstantsInner {
  constructor () {
    this.constants = new Map()
    const addOne = (key, defaultValue, regex, description) => {
      this.constants.set(key, new EhrConstant(key, defaultValue, regex, description))
    }
    addOne(
      'wbcFieldFactor',
      0.9,
      '--wbcFieldFactor--',
      'Hematology PBF report default WBC field factor'
    )
    addOne(
      'pltFieldFactor',
      11.1,
      '--pltFieldFactor--',
      'Hematology PBF report default PLT field factor'
    )
    addOne(
      'tubeSuction',
      '20 cm H2O',
      '--tubeSuction--',
      'Chest tube profile - default suction setting'
    )
  }
  listConstants () {
    return Array.from(this.constants.values())
  }

  getDescription (key) {
    const c = this.getConstant(key)
    return c?.description
  }

  getConstant (key) {
    return this.constants.get(key) || null
  }
  replaceDialogFormDefaults (formData) {
    const consumer = store.getters['consumerStore/consumer']
    const dbConstants = consumer.configConstants || {}
    let result = JSON.stringify(formData)
    // console.log('ehr data before', result)
    const ehrConstants = this.listConstants()
    ehrConstants.forEach(c => {
      const val = dbConstants[c.key] || c.defaultValue
      result = result.replace(c.regex, val)
    })
    // console.log('ehr data after', result)
    return JSON.parse(result)
  }

  async getConsumerConfigConstants () {
    return store.getters['consumerStore/configConstants'] || []
  }

  async updateConsumerConfigConstants (consumerId, configConstants) {
    let payload = { toolConsumerId: consumerId, configConstants: configConstants }
    await store.dispatch('consumerStore/updateConfigConstants', payload)
  }
}
const EhrConstants = new EhrConstantsInner()
export default EhrConstants
